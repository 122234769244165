import React from "react";
import { Link } from "react-router-dom";

const LinkRoundedFill = ({
  title,
  onclick,
  onchange,
  value,
  width,
  to = "/",
}) => {
  return (
    <Link
      to={to}
      style={{
        width: width,
      }}
      className="hover-btn-pill"
      onClick={onclick}
      onChange={onchange}
      value={value}
    >
      {title}
    </Link>
  );
};

export default LinkRoundedFill;
