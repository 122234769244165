/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import LinkRoundedFill from "../utility/LinkRoundedFill";
import { useTranslation } from "react-i18next";

const Landing = () => {
  const { t } = useTranslation();

  let text = [
    // "استشارتك الأولـى هي بدايـتك الـمهنيـة",
    // "استشرنا لتطوير نفسك وأعمالك",
    // "استشرنا لتحقيق التطور في عملك",
    t("randomLanding.one"),
    t("randomLanding.two"),
    t("randomLanding.three"),
  ];

  // "استثمارك الذكي في المستقبل",
  //   "رحـلتـك الأولـى لـبدايـتك الـمهنيـة",
  //   "وجـهتـك لـتحقيـق كـل نـجـاح",

  const [valText, setvalText] = useState(t("randomLanding.one"));

  useEffect(() => {
    setInterval(() => {
      let randomNumber = Math.floor(Math.random() * text.length);
      setvalText(text[randomNumber]);
    }, 4000);
  }, []);
  return (
    <div
      className="position-relative d-flex justify-content-center flex-column align-items-center"
      style={{
        backgroundImage: "url(../../images/img-riyadh.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "75vh",
      }}
    >
      <div
        className="position-absolute"
        style={{
          width: "100%",
          height: "100%",
          background: "#000",
          opacity: 0.5,
        }}
      ></div>

      <h1 className=" text-white text-landing" style={{ zIndex: 10 }}>
        {/* استثمارك الذكي في المستقبل */}
        {valText}
      </h1>
      <div style={{ zIndex: 10 }} className="d-flex  mt-3">
        <LinkRoundedFill to="/contact-us" title={t("start-now")} width={180} />
      </div>
    </div>
  );
};

export default Landing;
